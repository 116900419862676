import { Tooltip } from "@wisr/react-ui";
import React, { useState } from "react";
import highlightStyle from "./highlight.scss";

export interface HighlightItem {
  type: "main" | "small" | "full";
  title: string | JSX.Element;
  description: string;
  tooltip?: string;
  id?: string;
  message?: string[];
}

interface HighlightProps {
  list: HighlightItem[];
  variation?: "boxed" | "compact";
}

export const HighlightComponent: React.FC<HighlightProps> = ({
  list,
  variation,
}) => {
  const [showMessage, setShowMessage] = useState(false);

  return (
    <div className={`highlight ${variation || ""}`}>
      <style jsx>{highlightStyle}</style>
      <dl>
        {list.map((item, index) => {
          return (
            <div
              className={`highlight-item ${item.type}`}
              key={index}
              id={item.id}
            >
              <>
                <dd>{item.description}</dd>
                <dt>
                  {item.title}
                  {item.tooltip && (
                    <Tooltip size="small">{item.tooltip}</Tooltip>
                  )}
                </dt>
                {item.message?.length === 2 && (
                  <div className="message">
                    {showMessage ? (
                      <p
                        onClick={() => {
                          setShowMessage(false);
                        }}
                      >
                        {item.message[1]}
                      </p>
                    ) : (
                      <a
                        href="#"
                        onClick={() => {
                          setShowMessage(true);
                        }}
                      >
                        {item.message[0]}
                      </a>
                    )}
                  </div>
                )}
              </>
            </div>
          );
        })}
      </dl>
    </div>
  );
};
