import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import tz from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(tz);

export const convertTimeZone = (dateString: string) => {
  const timeZone = dayjs.tz.guess();
  const date: Dayjs = dayjs.utc(dateString.substring(0, 23)).tz(timeZone);
  return date.format("YYYY-MM-DDTHH:mm:ss");
};

export const stringToDate = (
  dateString: string,
  format?:
    | "full"
    | "short"
    | "total"
    | "month"
    | "month-year"
    | "two-digits"
    | "full-date",
  parsingFormat?: string
) => {
  const date: Dayjs = dayjs(dateString, parsingFormat);
  let formattedDate = "";
  if (date.isValid() && dateString) {
    switch (format) {
      case "short":
        formattedDate = date.format("DD MMM");
        break;
      case "full":
        formattedDate = date.format("DD MMM YYYY");
        break;
      case "two-digits":
        formattedDate = date.format("DD/MM/YY");
        break;
      case "month":
        formattedDate = date.format("MMM");
        break;
      case "month-year":
        formattedDate = date.format("MMM YY");
        break;
      case "full-date":
        formattedDate = date.format("dddd, DD MMM YYYY");
        break;
      default:
        formattedDate = date.format("DD/MM/YYYY");
        break;
    }
  }
  return formattedDate;
};
