import React from "react";
import { StatusStates } from "../status/status";
import style from "./badge.scss";
import statusStyle from "../status/status.scss";

interface BadgeProps {
  badge: StatusStates;
}

export const BadgeComponent: React.FC<BadgeProps> = ({ children, badge }) => {
  return (
    <div className={`badge ${badge}`}>
      <style jsx>{style}</style>
      <style jsx>{statusStyle}</style>
      {children}
    </div>
  );
};
