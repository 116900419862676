import React from "react";
import errorStyle from "./error-widget.scss";

export const ErrorWidgetComponent = () => {
  return (
    <div className="error-widget-wrapper">
      <style jsx>{errorStyle}</style>
      <div className="error-widget">
        <div className="error-content">
          <div className="icon">!</div>
          <h3>Oops</h3>
        </div>
        <div className="error-content">
          <p>
            We’re having trouble loading this information.
            <br />
            Refresh the page and try again.
          </p>
        </div>
      </div>
    </div>
  );
};
