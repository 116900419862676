import { Card } from "@wisr/react-ui";
import React from "react";
import widgetComponentStyle from "./widget-component.scss";
import widgetWrapperStyle from "./widget-wrapper.scss";

interface WidgetProps {
  widgetType: "credit" | "borrow" | "repay" | "other";
  widgetName: string;
  classes?: string;
}

export const WidgetComponent: React.FunctionComponent<WidgetProps> = ({
  children,
  widgetType,
  widgetName,
  classes = "",
}) => {
  return (
    <div
      className={`widget widget-type-${widgetType} widget-${widgetName} ${classes}`}
    >
      <style jsx>{widgetComponentStyle}</style>
      <Card>{children}</Card>
    </div>
  );
};
export const WidgetWrapper: React.FunctionComponent = ({ children }) => {
  return (
    <div className="widget-wrapper">
      <style jsx>{widgetWrapperStyle}</style>
      {children}
    </div>
  );
};
